<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      
      
      
      <template slot="links" v-if="role == 'admin'">

        <sidebar-item
          :link="{
            name: 'Beranda',
            icon: 'ni ni-square-pin text-primary',
            path: '/summary-home-page-user'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'fa fa-university text-primary',
            path: '/dashboards'
          }">
        </sidebar-item>


        <!-- <sidebar-item v-for="(item, index) in mymenu" :key="index" :link="{name: item.name, icon: 'fas fa-th-large text-primary'}">
          <sidebar-item v-for="(itemChild, indexChild) in item.privilege_category" :key="indexChild" :link="{ name: itemChild.name, path: itemChild.url }">
            <sidebar-item v-for="(itemLastChild, indexLastChild) in itemChild.privilege_category" :key="indexLastChild" :link="{ name: itemLastChild.name, path: itemLastChild.url }"/>
          </sidebar-item>
        </sidebar-item> -->

        <sidebar-item
          :link="{
            name: 'Verifikasi',
            icon: 'ni ni-folder-17 text-primary',
          }">
            <sidebar-item  :link="{ name: 'Sparing', path: '/verifikasi/sparing' }"/>
            <sidebar-item  :link="{ name: 'Uji Konektifitas', path: '/verifikasi/uji-konektivitas' }"/>
            <sidebar-item  :link="{ name: 'Pengolahan Kembali', path: '/verifikasi/pengolahan-air-kembali' }"/>
            <sidebar-item  :link="{ name: 'Pelaporan Progress', path: '/verifikasi/progress-report' }"/>
        </sidebar-item>


        

        

        

        <sidebar-item
          :link="{
            name: 'Data Sparing',
            icon: 'fas fa-th-large text-primary',
            path: '/data-sparing'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Laporan',
            icon: 'ni ni ni-books text-primary',
          }">
            <sidebar-item  :link="{ name: 'Data perjam', path: '/laporan/jam' }"/>
            <sidebar-item  :link="{ name: 'Data perhari', path: '/laporan/hari' }"/>
            <sidebar-item  :link="{ name: 'Data perbulan', path: '/laporan/bulan' }"/>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Status Pengiriman',
            icon: 'ni ni ni-books text-primary',
            path: '/status-pengiriman/2-menit'
          }">
        </sidebar-item>
        
        <sidebar-item
          :link="{
            name: 'Berita Acara Verifikasi Lapangan',
            icon: 'fas fa-briefcase text-primary',
            path: '/berita-acara'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Laporan Early Warning',
            icon: 'fas fa-th-large text-primary',
          }">
            <sidebar-item  :link="{ name: 'Per 1 jam', path: '/earlywarning/jam' }"/>
            <sidebar-item  :link="{ name: 'Per 1 hari', path: '/earlywarning/hari' }"/>
            <sidebar-item  :link="{ name: 'Per 1 bulan', path: '/earlywarning/bulan' }"/>
            <sidebar-item  :link="{ name: 'Uji Kalibrasi', path: '/uji-kalibrasi' }"/>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Analisa Perbandingan Hasil',
            icon: 'fas fa-th-large text-primary',
            path: '/analisa-perbandingan-hasil'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Laporan kondisi tidak normal',
            icon: 'fa fa-battery-quarter text-primary',
            path: '/laporan-kondisi-tidak-normal'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Laporan manual data satu minggu',
            icon: 'fa fa-link text-primary',
            path: '/laporan-manual-data/satu-minggu'
          }">
        </sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: 'Admin',
            icon: 'fas fa-th-large text-primary',
          }">
            <sidebar-item  :link="{ name: 'Industri', path: '/admin-company' }"/>
            <sidebar-item  :link="{ name: 'Sparing', path: '/admin-sparing' }"/>
            <sidebar-item  :link="{ name: 'Uji Konektifitas', path: '/admin-connectivity-test' }"/>
            <sidebar-item  :link="{ name: 'Pelaporan Progress', path: '/admin-reporting-progress' }"/>
            <sidebar-item  :link="{ name: 'Pengolahan Kembali', path: '/admin-reprocess' }"/>
        </sidebar-item> -->

        <div class="pt-5 px-4"><small>Admin</small></div>


        <sidebar-item
          :link="{
            name: 'Pengaturan',
            icon: 'ni ni-settings text-primary',
          }">
            <sidebar-item  :link="{ name: 'Pengumuman', path: '/setting-announcement' }"/>
            <sidebar-item  :link="{ name: 'Pertanyaan Umum', path: '/setting-faq' }"/>
            <sidebar-item  :link="{ name: 'Publikasi', path: '/setting-publikasi' }"/>
            <sidebar-item  :link="{ name: 'User', path: '/setting-user' }"/>
        </sidebar-item>


        <sidebar-item
          :link="{
            name: 'Master',
            icon: 'ni ni-settings-gear-65 text-primary',
          }">
            <sidebar-item  :link="{ name: 'Industri', path: '/master_company' }"/>
            <sidebar-item  :link="{ name: 'Database', path: '/admin-reprocess' }"/>
            <sidebar-item  :link="{ name: 'Kategori Pertanyaan', path: '/FAQ_Categories' }"/>
            <sidebar-item  :link="{ name: 'Jenis Akun', path: '/Role_User' }"/>
            <sidebar-item  :link="{ name: 'Jenis Industri', path: '/company_types' }"/>
            <sidebar-item  :link="{ name: 'Provinsi', path: '/region-provinces' }"/>
            <sidebar-item  :link="{ name: 'Kabupaten / Kota', path: '/region-regencies' }"/>
        </sidebar-item>



        <!-- <sidebar-item v-for="(item, index) in mymenu" :key="index" :link="{name: item.name, icon: 'fas fa-th-large text-primary', path: item.url}">
          <sidebar-item v-for="(itemChild, indexChild) in item.privilege_category" :key="indexChild" :link="{ name: itemChild.name, path: itemChild.url }">
          </sidebar-item>
        </sidebar-item> -->

      </template>

      <template slot="links" v-else>
        <sidebar-item
          :link="{
            name: 'Profile Perusahaan',
            icon: 'ni ni-badge text-primary',
            path: '/summary-company-profile'
          }">
        </sidebar-item>

        <div class="py-2"></div>

        <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Beranda',
            icon: 'ni ni-square-pin text-primary',
            path: '/summary-home-page-user'
          }">
        </sidebar-item>


        <sidebar-item
          :link="{
            name: 'Pendaftaran',
            icon: 'ni ni-folder-17 text-primary',
          }">
            <sidebar-item  :link="{ name: 'Sparing', path: '/sparing' }"/>
            <sidebar-item v-if="isReady" :link="{ name: 'Uji Konektifitas', path: '/uji-konektivitas' }"/>
            <sidebar-item v-if="isReady" :link="{ name: 'Pengolahan Kembali', path: '/pengolahan-kembali/air-limbah' }"/>
            <sidebar-item v-if="isReady" :link="{ name: 'Pelaporan Progress', path: '/pelaporan-progress' }"/>
            <sidebar-item v-if="isReady" :link="{ name: 'Surat Izin', path: '/surat-izin' }"/>
        </sidebar-item>

        <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Data Sparing',
            icon: 'fas fa-th-large text-primary',
            path: '/data-sparing'
          }">
        </sidebar-item>

        <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Laporan',
            icon: 'ni ni ni-books text-primary',
          }">
            <sidebar-item  :link="{ name: 'Data perjam', path: '/laporan/jam' }"/>
            <sidebar-item  :link="{ name: 'Data perhari', path: '/laporan/hari' }"/>
            <sidebar-item  :link="{ name: 'Data perbulan', path: '/laporan/bulan' }"/>
        </sidebar-item>

         <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Status Pengiriman',
            icon: 'ni ni ni-books text-primary',
            path: '/status-pengiriman/2-menit'
          }">
        </sidebar-item>

         <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Berita Acara Verifikasi Lapangan',
            icon: 'fas fa-briefcase text-primary',
            path: '/berita-acara'
          }">
        </sidebar-item>

         <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Laporan Early Warning',
            icon: 'fas fa-th-large text-primary',
          }">
            <sidebar-item  :link="{ name: 'Per 1 jam', path: '/earlywarning/jam' }"/>
            <sidebar-item  :link="{ name: 'Per 1 hari', path: '/earlywarning/hari' }"/>
            <sidebar-item  :link="{ name: 'Per 1 bulan', path: '/earlywarning/bulan' }"/>
            <sidebar-item  :link="{ name: 'Uji Kalibrasi', path: '/uji-kalibrasi' }"/>
        </sidebar-item>

        <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Laporan kondisi tidak normal',
            icon: 'fa fa-battery-quarter text-primary',
            path: '/laporan-kondisi-tidak-normal'
          }">
        </sidebar-item>

        <sidebar-item
          v-if="isReady"
          :link="{
            name: 'Laporan manual data satu minggu',
            icon: 'fa fa-link text-primary',
            path: '/laporan-manual-data/satu-minggu'
          }">
        </sidebar-item>

        <div class="pt-5 px-4"><small>Informasi</small></div>

         <sidebar-item
          :link="{
            name: 'Publikasi',
            icon: 'ni ni-single-copy-04 text-primary',
            path: '/setting-publikasi'
          }">
        </sidebar-item>

         <sidebar-item
          :link="{
            name: 'Pengumuman',
            icon: 'ni ni-single-copy-04 text-primary',
            path: '/summary-announcement'
          }">
        </sidebar-item>

         <sidebar-item
          :link="{
            name: 'Pertanyaan Umum',
            icon: 'ni ni-single-copy-04 text-primary',
            path: '/setting-faq'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Panduan Sparing',
            icon: 'ni ni-single-copy-04 text-primary',
            path: '/help-guide'
          }">
        </sidebar-item>
      </template>

      


    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  

  

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import API from "../../api/base_url";

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    data() {
      return {
        mymenu:[],
        role:"",
        isReady:true
      }
    },
    mounted() {
      this.role = localStorage.getItem('as')
      this.initScrollbar()
      const token = localStorage.getItem('token')
      // const headers = {
      //   Authorization: `Bearer ${token}`
      // }
      
      // API.get(`companies/sparings/permits` , {headers})
      //   .then(({data}) => {
      //     if (data.data !== null) {
      //       this.isReady = true
      //     } 
      //   })
      //   .catch((err) => {
      //     console.log(err);
      // })
    }
  };
</script>
<style scoped>
 .nav-link.active {
  background-color: #ededed!important;
}
</style>
