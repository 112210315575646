import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');
const UjiKonek = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/PendaftaranDepan/UjiKonektivitas.vue');
const PendaftaranSparing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/PendaftaranDepan/PendaftaranSparing.vue');
const PerubahanSparing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/PendaftaranDepan/PerubahanSparing.vue');
const PerubahanSparingAirKembali = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/PendaftaranDepan/PerubahanPengolahanAir.vue');
const PerubahanUjiKonek = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/PendaftaranDepan/PerubahanUjiKonek');
const Publikasi = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Publikasi.vue');
const PertanyaanUmum = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/PertanyaanUmum.vue');
const BebanPencemaran = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/BebanPencemaran.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');

// New Route

const AddSparing = () => import('@/views/Pages/Pendaftaran/Sparing/Create.vue')
const AddUjiKonektivitas = () => import('@/views/Pages/Pendaftaran/Uji-Konektivitas/Create.vue')

const Pengumuman = () => import('@/views/Pages/Pengumuman.vue')
const AdminPengumuman = () => import('@/views/Pages/Setting/PengumumanAdmin.vue')
const PengumumanView = () => import('@/views/Pages/PengumumanView.vue')
const Beranda = () => import('@/views/Pages/Beranda.vue')
const Dashboards = () => import('@/views/Pages/Dashboard.vue')
const DashboardView = () => import('@/views/Pages/DashboardView.vue')
const DataSparing = () => import('@/views/Pages/DataSparing.vue')
const LaporanPerJam = () => import('@/views/Pages/Laporan/PerJam.vue')
const LaporanPerHari = () => import('@/views/Pages/Laporan/PerHari.vue')
const LaporanPerBulan = () => import('@/views/Pages/Laporan/PerBulan.vue')
const StatusPengiriman = () => import('@/views/Pages/Laporan/StatusPengiriman.vue')
const AnalisaPerbandinganHasil = () => import('@/views/Pages/AnalisaPerbandinganHasil.vue')
const BeritaAcaraVerifikasiLapangan = () => import('@/views/Pages/BeritaAcaraVerifikasiLapangan.vue')
const EarlyWarningPerJam = () => import('@/views/Pages/EarlyWarning/PerJam.vue')
const EarlyWarningPerHari = () => import('@/views/Pages/EarlyWarning/PerHari.vue')
const EarlyWarningPerBulan = () => import('@/views/Pages/EarlyWarning/PerBulan.vue')
const EarlyWarningUjiKalibrasi = () => import('@/views/Pages/EarlyWarning/UjiKalibrasi.vue')
const AdminDatabase = () => import('@/views/Pages/Admin/Database.vue')
const AdminFormCovid19 = () => import('@/views/Pages/Admin/FormCovid19.vue')
const AdminFormCovid19View = () => import('@/views/Pages/Admin/FormCovid19View.vue')
const AdminIndustri = () => import('@/views/Pages/Admin/Industri.vue')
const AdminIndustriView = () => import('@/views/Pages/Admin/IndustriView.vue')
const AdminPendaftaranUjiKonek = () => import('@/views/Pages/Admin/PendaftaranUjiKonek.vue')
const AdminPendaftaranUjiKonekView = () => import('@/views/Pages/Admin/PendaftaranUjiKonekView.vue')
const AdminPertanyaanUmum = () => import('@/views/Pages/Setting/PertanyaanUmum.vue')
const AdminPublikasi = () => import('@/views/Pages/Setting/Publikasi.vue')
const AdminUser = () => import('@/views/Pages/Setting/User.vue')
const AdminUserView = () => import('@/views/Pages/Setting/UserView.vue')
const AdminValidasiPendaftaran = () => import('@/views/Pages/Admin/ValidasiPendaftaran.vue')
const AdminValidasiPendaftaranView = () => import('@/views/Pages/Admin/ValidasiPendaftaranView.vue')
const IsianLaporanTidakNormal = () => import('@/views/Pages/LaporanKondisiTidakNormal/IsianLaporanTidakNormal.vue')
const IsianLaporanTidakNormalView = () => import('@/views/Pages/LaporanKondisiTidakNormal/IsianLaporanTidakNormalView.vue')
const IsianLaporanTidakNormalAdd = () => import('@/views/Pages/LaporanKondisiTidakNormal/Add.vue')
const LaporanDataSatuDuaMinggu = () => import('@/views/Pages/LaporanManualSatuMinggu/LaporanDataSatuDuaMinggu.vue')
const PanduanSparing = () => import('@/views/Pages/PanduanSparing.vue')
const BantuanSparing = () => import('@/views/Pages/BantuanSparing.vue')
const PendaftaranLanjut = () => import('@/views/Pages/PendaftaranLanjut.vue')


const RegisPelaporanProgress = () => import('@/views/Pages/Pendaftaran/Pelaporan-Progres/Create.vue')
const RegisSparingLimbahAir = () => import('@/views/Pages/Pendaftaran/Pengolahan-Kembali/Create.vue')

const PelaporanProgressIndex = () => import('@/views/Pages/Pendaftaran/Pelaporan-Progres/Main.vue')
const PemanfaatanAirLimbahIndex = () => import('@/views/Pages/Pendaftaran/Pengolahan-Kembali/Main.vue')
const SparingIndex = () => import('@/views/Pages/Pendaftaran/Sparing/Main.vue')
const UjiKonektivitasPage = () => import('@/views/Pages/Pendaftaran/Uji-Konektivitas/Main.vue')

const EditUjiKonektivitas = () => import('@/views/Pages/Pendaftaran/Uji-Konektivitas/Update.vue')
const EditPelaporanProgress = () => import('@/views/Pages/Pendaftaran/Pelaporan-Progres/Update.vue')
const EditSparingNormal = () => import('@/views/Pages/Pendaftaran/Sparing/Update.vue')
const EditSparingLimbahAir = () => import('@/views/Pages/Pendaftaran/Pengolahan-Kembali/Update.vue')



// Validasi

// View User Pendaftaran
const DetailPendaftaranSparing = () => import('@/views/Pages/Pendaftaran/Sparing/Detail.vue')
const DetailPendaftaranUjiKonek = () => import('@/views/Pages/Pendaftaran/Uji-Konektivitas/Detail.vue')
const DetailPendaftaranAirLimbah = () => import('@/views/Pages/Pendaftaran/Pengolahan-Kembali/Detail.vue')
const DetailPendaftaranPelaporanProgress = () => import('@/views/Pages/Pendaftaran/Pelaporan-Progres/Detail.vue')

const MasterPermit = () => import('@/views/Pages/Pendaftaran/MasterPermit/Permit.vue')
const ProfilePerusahaan = () => import('@/views/Pages/ProfilPerusahaan.vue')
// Edit
const AddIndustri = () => import('@/views/Pages/Admin/IndustriTambah.vue')
const EditIndustri = () => import('@/views/Pages/Admin/IndustriEdit.vue')
// Verifikasi
const VerifikasiUjiKonektifitas = () => import('@/views/Pages/Verifikasi/UjiKonektifitas/UjiKonektifitas.vue')
const VerifikasiUjiKonektifitasView = () => import('@/views/Pages/Verifikasi/UjiKonektifitas/UjiKonektifitasView.vue')
const VerifikasiPelaporanProgress = () => import('@/views/Pages/Verifikasi/PelaporanProgress/PelaporanProgress.vue')
const VerifikasiPelaporanProgressView = () => import('@/views/Pages/Verifikasi/PelaporanProgress/PelaporanProgressView.vue')

const ValidasiSparing = () => import('@/views/Pages/Verifikasi/Sparing/Sparing.vue')
const ValidasiSparingDetail = () => import('@/views/Pages/Verifikasi/Sparing/SparingDetail.vue')

const VerifikasiPengolahankembali = () => import('@/views/Pages/Verifikasi/PengolahanKembali/PengolahanKembali.vue')
const VerifikasiPengolahankembaliView = () => import('@/views/Pages/Verifikasi/PengolahanKembali/PengolahanKembaliView.vue')

// Master Data
const FAQ_Categories = () => import('@/views/Pages/MasterData/Faq_Categories.vue')
const Role_User = () => import('@/views/Pages/MasterData/Role_User.vue')
const Company_Type = () => import('@/views/Pages/MasterData/Company_Types.vue')
const Region_Provinces = () => import('@/views/Pages/MasterData/Region_Provinces.vue')
const Region_Regencies = () => import('@/views/Pages/MasterData/Region_Regencies.vue')
const Company = () => import('@/views/Pages/MasterData/Company.vue')
const SelectRegis = () => import('@/views/Pages/PendaftaranDepan/SelectRegister.vue')
const PengolahanAirKembali = () => import('@/views/Pages/PendaftaranDepan/PengolahanAirKembali.vue')
const UjiKonektivitasFirst = () => import('@/views/Pages/PendaftaranDepan/UjiKonektivitas.vue')
const PelaporanCovid = () => import('@/views/Pages/PendaftaranDepan/PelaporanCovid.vue');


let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};

let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/elements',
  name: 'Forms',
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component:  FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component:  FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component:  FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};


































// akan digunakan

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    {
      path: '/ujiKonek',
      name: 'UjiKonek',
      component: UjiKonek
    },
   
    {
      path: '/sparing/pendaftaran-baru',
      name: 'PendaftaranSparing',
      component: PendaftaranSparing
    },
    {
      path: '/sparing/perubahan/:id',
      name: 'PerubahanSparing',
      component: PerubahanSparing
    },
    {
      path: '/sparing/pendaftaran-pengolahan-air-kembali/perubahan/:id',
      name: 'PerubahanSparingAirKembali',
      component: PerubahanSparingAirKembali
    },
    {
      path: '/uji-konektivitas/perubahan/:id',
      name: 'PerubahanUjiKonek',
      component: PerubahanUjiKonek
    },
    {
      path: '/Beban-Pencemaran',
      name: 'BebanPencemaran',
      component: BebanPencemaran
    },
    {
      path: '/publikasi',
      name: 'Publikasi',
      component: Publikasi
    },
    {
      path: '/pertanyaanUmum',
      name: 'PertanyaanUmum',
      component: PertanyaanUmum
    },
    
    {
      path: '/pilih-pendaftaran',
      name: 'Select Regis',
      component: SelectRegis,
    },
    {
      path: '/sparing/pendaftaran-pengolahan-air-kembali',
      name: 'WasteRecycle',
      component: PengolahanAirKembali,
    },
    {
      path: '/uji-konektivitas/pendaftaran-baru',
      name: 'UjiKonekFirst',
      component: UjiKonektivitasFirst,
    },
    {
      path: '/pelaporan-progress/pendaftaran-baru',
      name: 'PelaporanCovid',
      component: PelaporanCovid
    },
    { path: '*', component: NotFound }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Homes'
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/summary-home-page-user',
    // redirect: '/summary-home-page-admin',
    beforeEnter: (to, from, next) => {
      let myToken = localStorage.getItem('token')
      myToken ? next() : next({name:'Login'})
    },
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      },
      // disini ygy
      {
        path: '/sparing/daftar',
        name: 'DaftarSparing',
        component: AddSparing,
      },
      {
        path: '/uji-konektivitas/daftar',
        name: 'DaftarUjiKonek',
        component: AddUjiKonektivitas,
      },
      {
        path: '/summary-home-page-admin',
        name: 'BerandaAdmin',
        component: Beranda,
      },
      {
        path: '/summary-home-page-user',
        name: 'BerandaClient',
        component: Beranda,
      },
      {
        path: '/summary-announcement',
        name: 'Pengumuman',
        component: Pengumuman,
      },
      {
        path: '/dashboards',
        name: 'Dashboards',
        component: Dashboards,
      },
      {
        path: '/data-sparing',
        name: 'DataSparing',
        component: DataSparing,
      },
      {
        path: '/laporan/jam',
        name: 'LaporanPerJam',
        component: LaporanPerJam,
      },
      {
        path: '/laporan/hari',
        name: 'LaporanPerHari',
        component: LaporanPerHari,
      },
      {
        path: '/laporan/bulan',
        name: 'LaporanPerBulan',
        component: LaporanPerBulan,
      },
      {
        path: '/status-pengiriman/2-menit',
        name: 'StatusPengiriman',
        component: StatusPengiriman,
      },
      {
        path: '/analisa-perbandingan-hasil',
        name: 'AnalisaPerbandinganHasil',
        component: AnalisaPerbandinganHasil,
      },
      {
        path: '/earlywarning/jam',
        name: 'EarlyWarningPerJam',
        component: EarlyWarningPerJam,
      },
      {
        path: '/earlywarning/hari',
        name: 'EarlyWarningPerHari',
        component: EarlyWarningPerHari,
      },
      {
        path: '/earlywarning/bulan',
        name: 'EarlyWarningPerBulan',
        component: EarlyWarningPerBulan,
      },
      {
        path: '/uji-kalibrasi',
        name: 'EarlyWarningUjiKalibrasi',
        component: EarlyWarningUjiKalibrasi,
      },
      {
        path: '/admin-reprocess',
        name: 'AdminDatabase',
        component: AdminDatabase,
      },
      {
        path: '/admin-reporting-progress',
        name: 'AdminFormCovid19',
        component: AdminFormCovid19,
      },
      {
        path: '/adminFormCovid19View',
        name: 'AdminFormCovid19View',
        component: AdminFormCovid19View,
      },
      {
        path: '/admin-company',
        name: 'AdminIndustri',
        component: AdminIndustri,
      },
      {
        path: '/adminIndustriView:id',
        name: 'AdminIndustriView',
        component: AdminIndustriView,
      },
      {
        path: '/adminIndustriEdit:id',
        name: 'Edit Industri',
        component: EditIndustri,
      },
      {
        path: '/admin-connectivity-test',
        name: 'AdminPendaftaranUjiKonek',
        component: AdminPendaftaranUjiKonek,
      },
      {
        path: '/adminPendaftaranUjiKonekView',
        name: 'AdminPendaftaranUjiKonekView',
        component: AdminPendaftaranUjiKonekView,
      },
      {
        path: '/setting-faq',
        name: 'AdminPertanyaanUmum',
        component: AdminPertanyaanUmum,
      },
      {
        path: '/setting-announcement',
        name: 'AdminPengumuman',
        component: AdminPengumuman,
      },
      {
        path: '/setting-publikasi',
        name: 'AdminPublikasi',
        component: AdminPublikasi,
      },
      {
        path: '/setting-user',
        name: 'AdminUser',
        component: AdminUser,
      },
      {
        path: '/adminUserView',
        name: 'AdminUserView',
        component: AdminUserView,
      },
      {
        path: '/admin-sparing',
        name: 'AdminValidasiPendaftaran',
        component: AdminValidasiPendaftaran,
      },
      {
        path: '/adminValidasiPendaftaranView:id',
        name: 'AdminValidasiPendaftaranView',
        component: AdminValidasiPendaftaranView,
      },
      {
        path: '/laporan-kondisi-tidak-normal',
        name: 'Report-abnormal-condition',
        component: IsianLaporanTidakNormal,
      },
      {
        path: '/laporan-manual-data/satu-minggu',
        name: 'LaporanDataSatuDuaMinggu',
        component: LaporanDataSatuDuaMinggu,
      },
      {
        path: '/help-guide',
        name: 'PanduanSparing',
        component: PanduanSparing,
      },
      {
        path: '/help-faq',
        name: 'BantuanSparing',
        component: BantuanSparing,
      },
      {
        path: '/dashboardview',
        name: 'DashboardView',
        component: DashboardView,
      },
      {
        path: '/pengumumanview',
        name: 'PengumumanView',
        component: PengumumanView,
      },
      {
        path: '/laporan-kondisi-tidak-normal/rincian/:id',
        name: 'IsianLaporanTidakNormalView',
        component: IsianLaporanTidakNormalView,
      },
      {
        path: '/isianLaporanTidakNormalAdd',
        name: 'IsianLaporanTidakNormalAdd',
        component: IsianLaporanTidakNormalAdd,
      },
      {
        path: '/pendaftaranLanjut',
        name: 'PendaftaranLanjut',
        component: PendaftaranLanjut,
      },
      {
        path: '/pelaporan-progress/daftar',
        name: 'RegistrationPelaporanProgress',
        component: RegisPelaporanProgress,
      },
      {
        path: '/pengolahan-kembali/air-limbah/daftar',
        name: 'RegistrationSparingWasteWater',
        component: RegisSparingLimbahAir,
      },
      {
        path: '/berita-acara',
        name: 'ReportFieldVerification',
        component: BeritaAcaraVerifikasiLapangan,
      },
      {
        path: '/verifikasi/sparing',
        name: 'Registration Verification Sparing',
        component: ValidasiSparing,
      },
      {
        path: '/verifikasi/sparing/rincian/:id',
        name: 'Registration Verification Sparing Detail',
        component: ValidasiSparingDetail,
      },

      {
        path: 'sparing/rincian/:id',
        name: 'Detail Pendaftaran Sparing',
        component: DetailPendaftaranSparing,
      },
      {
        path: '/uji-konektivitas/rincian/:id',
        name: 'Detail Pendaftaran Uji Konek',
        component: DetailPendaftaranUjiKonek,
      },
      {
        path: '/pengolahan-kembali/rincian/:id',
        name: 'Detail Pendaftaran Air Limbah',
        component: DetailPendaftaranAirLimbah,
      },
      {
        path: '/pelaporan-progress/rincian/:id',
        name: 'Detail Pendaftaran Pelaporan Progress',
        component: DetailPendaftaranPelaporanProgress,
      },
      {
        path: '/surat-izin',
        name: 'Master Permit',
        component: MasterPermit,
      },
      {
        path: '/summary-company-profile',
        name: 'Profil Perusahaan',
        component: ProfilePerusahaan,
      },
      {
        path: '/add-company',
        name: 'Tambah Industri',
        component: AddIndustri,
      },
      {
        path: '/verifikasi/uji-konektivitas',
        name: 'Verifikasi Uji Konektifitas',
        component: VerifikasiUjiKonektifitas,
      },
      {
        path: '/verifikasi/uji-konektivitas/rincian/:id',
        name: 'Verifikasi Uji Konektifitas View',
        component: VerifikasiUjiKonektifitasView,
      },
      {
        path: '/verifikasi/progress-report',
        name: 'Verifikasi Laporan Progress',
        component: VerifikasiPelaporanProgress,
      },
      {
        path: '/verifikasi/progress-report/rincian/:id',
        name: 'Verifikasi Laporan Progress View',
        component: VerifikasiPelaporanProgressView,
      },
      {
        path: '/verifikasi/pengolahan-air-kembali',
        name: 'Verifikasi Pengolahan kembali',
        component: VerifikasiPengolahankembali,
      },
      {
        path: '/verifikasi/pengolahan-air-kembali/rincian/:id',
        name: 'Verifikasi Pengolahan kembali View',
        component: VerifikasiPengolahankembaliView,
      },
      {
        path: '/faq_categories',
        name: 'FAQ_Categories',
        component: FAQ_Categories,
      },
      {
        path: '/role_user',
        name: 'Role User',
        component: Role_User,
      },
      {
        path: '/company_types',
        name: 'Company Type',
        component: Company_Type,
      },
      {
        path: '/region-provinces',
        name: 'Region Provinces',
        component: Region_Provinces,
      },
      {
        path: '/region-regencies',
        name: 'Region Regencies',
        component: Region_Regencies,
      },
      {
        path: '/master_company',
        name: 'Company',
        component: Company,
      },
      {
        path: '/pelaporan-progress',
        name: 'Pelaporan Progress Main',
        component: PelaporanProgressIndex,
      },
      {
        path: '/pengolahan-kembali/air-limbah',
        name: 'Pemanfaatan Air Limbah',
        component: PemanfaatanAirLimbahIndex,
      },
      {
        path: '/sparing',
        name: 'Sparing Page',
        component: SparingIndex,
      },
      {
        path: '/uji-konektivitas',
        name: 'Uji Konektifitas',
        component: UjiKonektivitasPage,
      },
      // edit pendaftaran =====================
      {
        path: '/uji-konektivitas/revisi/:id',
        name: 'Edit Uji Konektifitas',
        component: EditUjiKonektivitas,
      },
      {
        path: '/pelaporan-progress/update/:id',
        name: 'Edit Pelaporan Progress',
        component: EditPelaporanProgress,
      },
      {
        path: '/edit-sparing:id',
        name: 'Edit Sparing',
        component: EditSparingNormal,
      },
      {
        path: '/pengolahan-kembali/update/:id',
        name: 'Edit Limbah air',
        component: EditSparingLimbahAir,
      },
      

    ]
  },
  authPages,
];

export default routes;
