import axios from 'axios'



const url = "https://api.dev-klhk.rnbteknologiindonesia.com";
const dev = "http://89.116.20.73:8081"
const project = "api";
const version = "v1"

export default axios.create({
    baseURL:`${url}/${project}/${version}/`
})

// import axios from 'axios'

// const oldurl = "https://dev.api.sparing.zennithglobal.technology";
// const oldproject = "api";
// const oldversion = "v-1"

// export default axios.create({
//     baseURL:`${oldurl}/rest-${oldproject}/${oldversion}/`
// })

// const url = "https://api.dev-klhk.rnbteknologiindonesia.com";
// const dev = "https://0bb5-2400-9800-1e1-4bf7-e954-60a9-c2e0-e901.ngrok-free.app";
// const project = "api";
// const version = "v1"

// const axiosInstance = axios.create({
//     baseURL: `${url}/${project}/${version}/`
// });


// axiosInstance.interceptors.request.use(config => {
//     config.headers['ngrok-skip-browser-warning'] = 'true';

//     return config;
// });

// export default axiosInstance;