<template>
  <div style="overflow:hidden;">
    <div class="marquee" v-if="this.$route.name == 'Home'" >
      <p>{{ dummyFooter }}</p>
    </div>
    <!-- <div
    class="cover"
    :style="{ backgroundImage: `url('img/theme/img-background-home.png')` }"
    ></div> -->
    <base-nav
      v-model="showMenu"
      type="light"
      v-if="this.$route.name == 'Home' || this.$route.name == 'Publikasi' || this.$route.name == 'PertanyaanUmum'|| this.$route.name == 'BebanPencemaran'|| this.$route.name == 'Login'"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main"
      style="marginTop:35px;"
      expand="lg"
    >

      <div slot="brand" class="navbar-wrapper d-flex align-items-center mr-30">
        <router-link class="" to="/">
          <div>
            <img src="img/brand/img-logo-color.png"/>
          </div>
        </router-link>
      </div>

     <template>
       <div class="navbar-collapse-header">
         <b-row>
           <b-col cols="6" class="collapse-brand">
             <router-link to="/">
               <img src="img/brand/img-logo-color.png">
             </router-link>
           </b-col>
           <b-col cols="6" class="collapse-close">
             <button type="button" class="navbar-toggler" @click="showMenu = false">
               <span></span>
               <span></span>
             </button>
           </b-col>
         </b-row>
       </div>

       <b-navbar-nav class="mr-auto">
         <b-nav-item to="/Publikasi">
             <span class="nav-link-inner--text">Publikasi</span>
         </b-nav-item>
         <b-nav-item to="/PertanyaanUmum">
             <span class="nav-link-inner--text">Pertanyaan Umum</span>
         </b-nav-item>
         <b-nav-item to="/Beban-Pencemaran">
             <span class="nav-link-inner--text">Beban Pencemaran</span>
         </b-nav-item>
       </b-navbar-nav>
       <hr class="d-lg-none">
       
       <b-navbar-nav  class="align-items-lg-center ml-lg-auto">
          <!-- <li class="nav-item d-none d-lg-block ml-lg-4">
            <b-dropdown id="dropdown-1" text="Pendaftaran" class="m-md-2">
              <b-dropdown-item to="/UjiKonek">Pendaftaran Uji Konektivitas</b-dropdown-item>
              <b-dropdown-item to="/PelaporanCovid">Pendaftaran Pelaporan Kendala Covid 19</b-dropdown-item>
              <b-dropdown-item to="/PendaftaranSparing">Pendaftaran Sparing</b-dropdown-item>
            </b-dropdown>
          </li> -->

          <li class="nav-item d-none d-lg-block mr--3 ">
           <b-nav-item to="/pilih-pendaftaran">
           <!-- <b-nav-item to="/sparing/pendaftaran-baru"> -->
            <button class="btn btn-secondary" >DAFTAR</button>
          </b-nav-item>

          

          <!-- <b-dropdown id="dropdown-1" variant="primary" text="Primary" class="m-md-2" style=" z-index: 999;">
            <b-dropdown-item>Action</b-dropdown-item>
            <b-dropdown-item>Another action</b-dropdown-item>
            <b-dropdown-item>Something else here</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item active>Separated link</b-dropdown-item>
          </b-dropdown> -->
         </li>
         <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
         <li class="nav-item d-none d-lg-block ">
           <b-nav-item to="/login">
           <button class="btn btn-primary" v-if="this.$route.name != 'Login'" >MASUK</button>
          </b-nav-item>
         </li>
       </b-navbar-nav>
     </template>
    </base-nav>

    <div class="main-content">
      <zoom-center-transition
        :duration="pageTransitionDuration"
        mode="out-in"
      >
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <footer class="py-5" id="footer-main">
      <b-container >
        <b-row align-v="center" class="justify-content-xl-between">
          <b-col xl="12">
            <h4 class="
                d-flex
                flex-column
                justify-content-center
                text-center text-white text-footers
              "
                >
                <span>Direktorat Pengendalian Pencemaran Air</span>
                <span>Direktorat Jenderal Pengendalian Penemaran dan Kerusakan Lingkungan</span>
                <span>Kementerian Lingkungan Hidup dan Kehutanan</span>
                <span class="d-md-block d-none">&copy; copyright 2019 - {{ new Date().getFullYear() }}. Hak cipta dilindungi Undang - Undang</span>
                <span class="d-md-none">&copy; copyright 2019 - {{ new Date().getFullYear() }}</span>
                <span class="d-md-none">Hak cipta dilindungi Undang - Undang</span>
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>
  import { BaseNav } from '@/components';
  import { ZoomCenterTransition } from 'vue2-transitions';

  export default {
    components: {
      BaseNav,
      ZoomCenterTransition
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },
    data() {
      return {
        showMenu: false,
        menuTransitionDuration: 250,
        pageTransitionDuration: 200,
        year: new Date().getFullYear(),
        pageClass: 'login-page',
        currentRoute:'home',
        dummyFooter: ' Yth. Bapak/Ibu Penanggung Jawab Kegiatan Usaha dan/atau Kegiatan Wajib SPARING Telah Terkoneksi     Sehubungan dengan adanya permintaan update data pendaftaran SPARING dapat kami informasikan untuk dapat melengkapi data yang diminta diantaranya: Jadwal Kalibrasi dan tanggal Kalibrasi setiap parameter. Update data pendaftaran bagi perusahaan yang telah terkoneksi, untuk dokumen permohonan koneksi dapat mengunggah  Surat Pernyataan Telah Terkoneksi . Demikian informasi kami sampaikan, mohon maaf atas ketidaknyamananya. '
      };
    },
    computed: {
      title() {
        return `${this.$route.name} Page`;
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open');
        this.showMenu = !this.showMenu;
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        this.showMenu = false;
      },
      setBackgroundColor() {
        document.body.classList.add('bg-default');
      },
      removeBackgroundColor() {
        document.body.classList.remove('bg-default');
      },
      updateBackground() {
        if (!this.$route.meta.noBodyBackground) {
          this.setBackgroundColor();
        } else {
          this.removeBackgroundColor()
        }
      }
    },
    beforeDestroy() {
      this.removeBackgroundColor();
    },
    beforeRouteUpdate(to, from, next) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        this.closeMenu();
        setTimeout(() => {
          next();
        }, this.menuTransitionDuration);
      } else {
        next();
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler: function () {
          this.updateBackground()
        }
      }
    }
  };
</script>
<style lang="scss">
  $scaleSize: 0.8;
  @keyframes zoomIn8 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
      opacity: 1;
    }
  }

  .main-content .zoomIn {
    animation-name: zoomIn8;
  }

  @keyframes zoomOut8 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }

  .main-content .zoomOut {
    animation-name: zoomOut8;
  }

  .mr-30 {
    margin-right: 30px;
  }

  .marquee {
    color: #000000;
    background-color: #FFC107;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .marquee p {
    font-size: 14px;
    font-weight: 500;
    height: 10px;
    padding-left: 100%;
    animation: marquee 70s linear infinite;
  }
  @keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }

  .cover {
    height: 100vh;
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  .nav-bg {
    background-size: cover;
  }
</style>
