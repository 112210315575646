var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[(_vm.role == 'admin')?_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Beranda',
          icon: 'ni ni-square-pin text-primary',
          path: '/summary-home-page-user'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'fa fa-university text-primary',
          path: '/dashboards'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Verifikasi',
          icon: 'ni ni-folder-17 text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Sparing', path: '/verifikasi/sparing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Uji Konektifitas', path: '/verifikasi/uji-konektivitas' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Pengolahan Kembali', path: '/verifikasi/pengolahan-air-kembali' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Pelaporan Progress', path: '/verifikasi/progress-report' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Data Sparing',
          icon: 'fas fa-th-large text-primary',
          path: '/data-sparing'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan',
          icon: 'ni ni ni-books text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Data perjam', path: '/laporan/jam' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Data perhari', path: '/laporan/hari' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Data perbulan', path: '/laporan/bulan' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Status Pengiriman',
          icon: 'ni ni ni-books text-primary',
          path: '/status-pengiriman/2-menit'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Berita Acara Verifikasi Lapangan',
          icon: 'fas fa-briefcase text-primary',
          path: '/berita-acara'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan Early Warning',
          icon: 'fas fa-th-large text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Per 1 jam', path: '/earlywarning/jam' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Per 1 hari', path: '/earlywarning/hari' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Per 1 bulan', path: '/earlywarning/bulan' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Uji Kalibrasi', path: '/uji-kalibrasi' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Analisa Perbandingan Hasil',
          icon: 'fas fa-th-large text-primary',
          path: '/analisa-perbandingan-hasil'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan kondisi tidak normal',
          icon: 'fa fa-battery-quarter text-primary',
          path: '/laporan-kondisi-tidak-normal'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan manual data satu minggu',
          icon: 'fa fa-link text-primary',
          path: '/laporan-manual-data/satu-minggu'
        }}}),_c('div',{staticClass:"pt-5 px-4"},[_c('small',[_vm._v("Admin")])]),_c('sidebar-item',{attrs:{"link":{
          name: 'Pengaturan',
          icon: 'ni ni-settings text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Pengumuman', path: '/setting-announcement' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Pertanyaan Umum', path: '/setting-faq' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Publikasi', path: '/setting-publikasi' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'User', path: '/setting-user' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Master',
          icon: 'ni ni-settings-gear-65 text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Industri', path: '/master_company' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Database', path: '/admin-reprocess' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Kategori Pertanyaan', path: '/FAQ_Categories' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Jenis Akun', path: '/Role_User' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Jenis Industri', path: '/company_types' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Provinsi', path: '/region-provinces' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Kabupaten / Kota', path: '/region-regencies' }}})],1)],1):_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Profile Perusahaan',
          icon: 'ni ni-badge text-primary',
          path: '/summary-company-profile'
        }}}),_c('div',{staticClass:"py-2"}),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Beranda',
          icon: 'ni ni-square-pin text-primary',
          path: '/summary-home-page-user'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Pendaftaran',
          icon: 'ni ni-folder-17 text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Sparing', path: '/sparing' }}}),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{ name: 'Uji Konektifitas', path: '/uji-konektivitas' }}}):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{ name: 'Pengolahan Kembali', path: '/pengolahan-kembali/air-limbah' }}}):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{ name: 'Pelaporan Progress', path: '/pelaporan-progress' }}}):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{ name: 'Surat Izin', path: '/surat-izin' }}}):_vm._e()],1),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Data Sparing',
          icon: 'fas fa-th-large text-primary',
          path: '/data-sparing'
        }}}):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan',
          icon: 'ni ni ni-books text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Data perjam', path: '/laporan/jam' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Data perhari', path: '/laporan/hari' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Data perbulan', path: '/laporan/bulan' }}})],1):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Status Pengiriman',
          icon: 'ni ni ni-books text-primary',
          path: '/status-pengiriman/2-menit'
        }}}):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Berita Acara Verifikasi Lapangan',
          icon: 'fas fa-briefcase text-primary',
          path: '/berita-acara'
        }}}):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan Early Warning',
          icon: 'fas fa-th-large text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Per 1 jam', path: '/earlywarning/jam' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Per 1 hari', path: '/earlywarning/hari' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Per 1 bulan', path: '/earlywarning/bulan' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Uji Kalibrasi', path: '/uji-kalibrasi' }}})],1):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan kondisi tidak normal',
          icon: 'fa fa-battery-quarter text-primary',
          path: '/laporan-kondisi-tidak-normal'
        }}}):_vm._e(),(_vm.isReady)?_c('sidebar-item',{attrs:{"link":{
          name: 'Laporan manual data satu minggu',
          icon: 'fa fa-link text-primary',
          path: '/laporan-manual-data/satu-minggu'
        }}}):_vm._e(),_c('div',{staticClass:"pt-5 px-4"},[_c('small',[_vm._v("Informasi")])]),_c('sidebar-item',{attrs:{"link":{
          name: 'Publikasi',
          icon: 'ni ni-single-copy-04 text-primary',
          path: '/setting-publikasi'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Pengumuman',
          icon: 'ni ni-single-copy-04 text-primary',
          path: '/summary-announcement'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Pertanyaan Umum',
          icon: 'ni ni-single-copy-04 text-primary',
          path: '/setting-faq'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Panduan Sparing',
          icon: 'ni ni-single-copy-04 text-primary',
          path: '/help-guide'
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }