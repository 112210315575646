/*!

=========================================================
* BootstrapVue Argon Dashboard PRO - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import VueStepWizard from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'

import leaflet from 'leaflet'

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';



// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueStepWizard);
Vue.use(VueSweetalert2);
Vue.use(VueApexCharts);
Vue.use(leaflet)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
});
